import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import ContactForm from "components/forms/ContactUs";

import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/aboutimg1.png";
// import prototypeIllustrationImageSrc from "images/aboutimg-2.png";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>About Us</Subheading>}
        heading={
          <>
            What Makes Us <HighlightedText>Stand Out?</HighlightedText>
          </>
        }
      />
      {/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
      <FeatureWithSteps
        // subheading={<Subheading></Subheading>}
        // heading={
        //   <>
        //     Why <HighlightedText>Choose Paydoh?</HighlightedText>
        //   </>
        // }
        // textOnLeft={false}
        // imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      <Testimonial
        subheading={<Subheading>Our Team</Subheading>}
        heading={
          <>
            The Team that <HighlightedText>lives the mission</HighlightedText>
          </>
        }
        // testimonials={[
        //   {
        //     // stars: 5,
        //     profileImageSrc:
        //       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
        //     heading: "Amazing User Experience",
        //     quote:
        //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
        //     customerName: "Charlotte Hale",
        //     customerTitle: "Director, Delos Inc."
        //   },
        //   {
        //     // stars: 5,
        //     profileImageSrc:
        //       "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
        //     heading: "Love the Developer Experience and Design Principles !",
        //     quote:
        //       "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        //     customerName: "Adam Cuppy",
        //     customerTitle: "Founder, EventsNYC"
        //   }
        // ]}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            Join the Paydoh <HighlightedText>Revolution</HighlightedText>
          </>
        }
        // imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        // features={[
        //   {
        //     Icon: MoneyIcon,
        //     title: "Affordable",
        //     description: "We promise to offer you the best rate we can - at par with the industry standard.",
        //     iconContainerCss: tw`bg-green-300 text-green-800`
        //   },
        //   {
        //     Icon: BriefcaseIcon,
        //     title: "Professionalism",
        //     description: "We assure you that our templates are designed and created by professional designers.",
        //     iconContainerCss: tw`bg-red-300 text-red-800`
        //   }
        // ]}
      />
      <ContactForm/>
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Is Paydoh a Bank?",
            answer: "No. It is a completely digital Savings Bank account for the Gen Z offered in partnership with Karur Vysya Bank . KVB is an RBI Licensed Bank and has been in operation for 97 years."
          },
          {
            question: "What are the charges for a Paydoh account?",
            answer: "A one-time charge of Rs 200 plus taxes is charged for the Debit Card. Rs 90 plus taxes per quarter as service charge for all the exciting services offered on Paydoh."
          },
          {
            question: "Do I get UPI with the account?",
            answer: "Yes. The Paydoh account comes with a linked UPI ID that is ready for use straightaway."
          },
          {
            question: "Is my money safe with Paydoh?",
            answer: "Yes. Your money is held with an RBI Licensed Bank and is completely safe. All deposits upto Rs 5 Lac made with a Bank are insured as per RBI regulations."
          },
          {
            question: "Is there any minimum age of opening a Paydoh account?",
            answer: "You can open an account with Paydoh if you are 13 years or older"
          },
          {
            question: "Does Paydoh have physical branches?",
            answer: "Paydoh is a digital account and hence does not have any branches. Your queries can be conveniently handled by using any of the options from the Help Section of Paydoh without needing to visit a branch."
          },
          {
            question: "Can the Paydoh Card be used internationally?",
            answer: "Yes, the Paydoh Card being a VISA Card is accepted at all establishments internationally that accept VISA Cards."
          },
          {
            question: "What kind of card do I get with my Paydoh account?",
            answer: "Your Paydoh account comes with a personalised VISA Debit Card. You can use the card to shop, pay online and withdraw cash."
          }
        ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
