import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import ModernBankingIcon from "images/digital-accout-opening.png";
import SmartShopIconImage from "images/1per-cashback-more.png";
import FinancialIconImage from "images/budget-save-smart.png";
import ExpenseIconImage from "images/splitbill.png";
import BankingServiceIconImage from "images/split-bills-hassless.png";
import RewardsIconImage from "images/1per-cashback.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-2 flex-shrink-0`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-sm text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "About Us", description = "Paydoh understands that traditional banking doesn't always align with the preferences of the tech-savvy Gen Z population. That's why we've teamed up with a prominent banking partner to introduce a cutting-edge neo banking solution tailored specifically for your needs." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ModernBankingIcon,
      title: "Modern Banking for the Digital Age",
      description: "Say goodbye to the complexities of traditional banking. With Paydoh, we've made banking intuitive and accessible. Your entire bank is now in your pocket."
    },
    { imageSrc: SmartShopIconImage, 
      title: "Smart Shopping Solutions",
      description: "We've elevated your banking experience by seamlessly integrating convenient shopping options into our platform. Shop through our carefully selected network of partners, including top e-commerce destinations, and enjoy exclusive benefits with every purchase. It's shopping made smarter!"
    },
    { imageSrc: FinancialIconImage, 
      title: "Effective Financial Management",
      description: "Gain financial control effortlessly with Paydoh's smart budget management tools. Set and monitor your spending limits, gain insights into your financial habits, and achieve your savings goals with ease."
    },
    { imageSrc: ExpenseIconImage, 
      title: "Simplified Expense Sharing",
      description: "Splitting bills with friends or roommates has never been simpler. Paydoh's bill-splitting feature ensures that sharing expenses & sqauring account is quick and straight forward."
    },
    { imageSrc: BankingServiceIconImage, 
      title: "Comprehensive Banking Services",
      description: "With Paydoh, you can access a full range of banking services right from the app. Transfer money, settle bills, and manage your account conveniently and efficiently."
    },
    { imageSrc: RewardsIconImage, 
      title: "Rewards for Savvy Shoppers",
      description: "Enhance your shopping experience with Paydoh. Earn cashback and enjoy rewards each time you shop through our partner network. It's an added bonus while you indulge in retail therapy."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="aboutus">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
