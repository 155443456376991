import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from 'styles/GlobalStyles';
import SaaSProductLandingPage from "page/SaaSProductLandingPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <>
      <ToastContainer />
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path='/' element={<SaaSProductLandingPage/>}/>
        </Routes>
      </Router>
    </>
  );
}