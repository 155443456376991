import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-40 mt-6 lg:mt-10`;

const ModalOverlay = styled.div`
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
background-color: white;
border-radius: 8px;
padding: 20px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
width: 400px;
height: 200px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #243e63;
  border-radius: 50%;
`;

const CloseIcon = styled.svg`
  width: 50%;
  height: 50%;
`;
const ContentDiv = styled.div`
  display: inline-block;
  font-size: 18px;
`;

const ContactLink = styled.a`
  text-decoration: underline;
  color: #6415ff;
  font-size: 22px;
`;

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  submitButtonText = "Connect with Us",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container id="contactus">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <SubmitButton onClick={openModal}>{submitButtonText}</SubmitButton>
            {isModalOpen && (
              <ModalOverlay>
                <ModalContent>
                  <CloseButton onClick={closeModal}>
                    <CloseIcon
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L19 19M19 1L1 19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </CloseIcon>
                  </CloseButton>
                  <ContentDiv>
                    <h4>Write to us at</h4>
                    <ContactLink href="mailto:it@paydoh.money">it@paydoh.money</ContactLink>
                  </ContentDiv>
                </ModalContent>
              </ModalOverlay>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
